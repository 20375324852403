import React, { useState, useEffect } from 'react'; // Import React too if not already done
import './App.css';

export default function App() {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const updateTime = () => {
      const currentTime = new Date();
      setTime(currentTime.toLocaleTimeString());
    };

    // Update the time initially
    updateTime();

    // Update the time every second
    const intervalId = setInterval(updateTime, 10);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="App">
      <h1>{time}</h1>
    </div>
  );
}
